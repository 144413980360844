<template>
  <div class="login-page">
    <div class="welcome">
      <div class="title theme-word-color f36 font-b font-bold">登录</div>
      <div class="sub-title f28 color-999">登录到Pie universe</div>
    </div>
    <div class="content">
      <div class="othen-login border-btn" @click="toDappLogin">
        <img class="wallet" src="../assets/images/icon-wallet.svg" alt="" />
        <div class="word color-333 f28 font-m">通过Dapp登录</div>
        <img class="right" src="../assets/images/swiper-right.png" alt="" />
      </div>
      <div class="form-block">
        <div class="form-item">
          <div class="label f30 color-333 font-m require">邮箱</div>
          <div class="input-box">
            <input type="text" v-model="email" placeholder="请输入邮箱" />
          </div>
        </div>
        <div class="form-item">
          <div class="label f30 color-333 font-m require">密码</div>
          <div class="input-box">
            <input type="password" v-model="password" placeholder="请输入密码" />
          </div>
        </div>
        <div class="forget f28 font-m theme-word-color">
          <a href="#/forget">忘记密码?</a>
        </div>
        <div class="btn-block">
          <div class="btn btn-register" @click="login">确认登录</div>
          <div class="border-btn">
            <span class="theme-word-color">取消登录</span>
          </div>
        </div>
        <div class="register-tip f28">
          没有账户?<a href="#/register" class="theme-word-color">点击这里注册</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {setToken} from "../common/token";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      email: "test123456@gmail.com",
      password: "123456",
    };
  },
  methods: {
    login() {
      this.$http
        .emailLogin({
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          if (res.code === 0) {
            // 登录成功后设置token
            setToken(res.data.token);
            this.$router.push("/index");
          }
        });
    },
    toDappLogin() {
      // 跳转到 Dapp登录页
      this.$router.push("/dapp-login");
    },
  },
};
</script>

<style lang="less">
.login-page {
  .welcome {
    padding: 1.36rem 0.48rem 0.96rem 0.48rem;
    background: url("../assets/images/login-bg2.png") no-repeat top center;
    background-size: cover;
    text-align: left;
    .title {
      line-height: 0.54rem;
      margin-bottom: 0.1rem;
    }
    .sub-title {
      line-height: 0.4rem;
    }
  }
  .content {
    padding: 0.48rem 0.48rem;
    transform: translateY(-0.48rem);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    background: #fff;
    .othen-login {
      display: flex;
      justify-content: center;
      padding: 0.16rem 0;
      .wallet {
        width: 0.48rem;
        height: 0.48rem;
      }
      .word {
        line-height: 0.48rem;
        margin: 0 0.1rem;
      }
      .right {
        width: 0.3rem;
        height: 0.3rem;
        margin-top: 0.09rem;
      }
    }
    .form-block {
      .form-item {
        text-align: left;
        margin-top: 0.48rem;
        .label {
          margin-bottom: 0.16rem;
          line-height: 0.4rem;
        }
        .label.require::after {
          content: "*";
          display: inline-block;
          color: #fd4c4c;
          padding-left: 2px;
        }
        .input-box {
          display: flex;
          border: 1px solid #eee;
          padding: 0 0.2rem;
          border-radius: 0.2rem;
          overflow: hidden;
          input {
            width: 100%;
            height: 0.8rem;
            color: #333;
            font-size: 0.28rem;
          }
        }
      }
      .forget {
        text-align: right;
        background: linear-gradient(
          90deg,
          #00cdff 0%,
          #2993ff 60%,
          #458fff 80%,
          #52d3ba 95%,
          #63ff92 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 0.3rem;
        margin-top: 0.32rem;
      }
      .btn-block {
        margin-top: 1.4rem;
        .btn-register {
          width: 100%;
          height: 0.8rem;
          line-height: 0.8rem;
          font-size: 0.28rem;
          font-weight: 400;
          margin-bottom: 0.3rem;
        }
        .border-btn {
          height: 0.8rem;
          line-height: 0.8rem;
          font-size: 0.28rem;
        }
      }
      .register-tip {
        margin-top: 0.3rem;
        color: #999;
        a {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
